import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

const StyledIconButton = styled.button`
  cursor: pointer;
  align-items: center;
  background-color: transparent;
  border: none;
  border: 0;
  margin: 0 8px;
  padding: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}

  ${({ theme }) => css`
    svg {
      width: 28px;
      height: 28px;
      padding: 14px;

      *[stroke] {
        stroke: ${theme.designTokens.color.text.primary};
      }

      @media (min-width: ${theme.breakpoints.S}px) {
        width: 40px;
        height: 40px;
        padding: 12px;
      }
    }
  `}
`;

export type IconButtonProps = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  nonInteractive?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
    value?: number | string
  ) => void;
};

export const IconButton: React.FC<IconButtonProps> = ({
  children,
  className,
  disabled,
  nonInteractive,
  onClick,
}) => (
  <StyledIconButton
    className={className}
    disabled={disabled}
    onClick={disabled ? undefined : onClick}
    tabIndex={nonInteractive ? -1 : 0}
  >
    {children}
  </StyledIconButton>
);
