import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import { KeyboardEvent } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import {
  useClickHandler,
  useDispatch,
  usePlayerState,
  useUiCallbacks,
} from "../components/context-provider";
import { useFullscreen } from "../components/fullscreen.hook";
import { useVolume } from "../components/volume.hook";
import { UiEventTypes } from "../constants";

export enum Hotkeys {
  CLOSE = "esc",
  DECREASE_VOL = "down",
  FULLSCREEN = "f",
  INCREASE_VOL = "up",
  MUTE = "m",
  RELADED_PREV = "p",
  RELATED = "r",
  RELATED_NEXT = "n",
  SKIP_BACK = "left",
  SKIP_FORWARD = "right",
  TAB = "tab",
  TOGGLE_PLAY = "space",
  TOGGLE_STARTOVER = "s",
}

export enum HotkeyTooltips {
  CLOSE = "esc",
  DECREASE_VOL = "↓",
  FULLSCREEN = "f",
  INCREASE_VOL = "↑",
  MUTE = "m",
  RELADED_PREV = "p",
  RELATED = "r",
  RELATED_NEXT = "n",
  SKIP_BACK = "←",
  SKIP_FORWARD = "→",
  TOGGLE_PLAY = "␣",
  TOGGLE_STARTOVER = "s",
}

export type TUseKeyboardCommandsOptions = {
  skip: (direction: number) => void;
};

export const useGlobalHotkeys = ({
  skip,
}: TUseKeyboardCommandsOptions): void => {
  const { backButtonCallback } = useUiCallbacks();
  const {
    fullscreen,
    loading,
    metadata: { relatedContent: relatedContentLegacy },
    modalOptions,
    relatedContent,
    showRelatedContentOverlay,
    uiMenuOpened,
  } = usePlayerState();
  const { onTogglePlay } = useClickHandler();
  const { decreaseVolume, increaseVolume, toggleMute } = useVolume();
  const { toggleFullscreen } = useFullscreen();
  const dispatch = useDispatch();

  useHotkeys("shift+d", () =>
    dispatch({
      payload: {},
      type: UiEventTypes.TOGGLE_STATISTICS,
    })
  );
  useHotkeys(Hotkeys.TAB, () =>
    dispatch({
      payload: {},
      type: UiEventTypes.SHOW_UI,
    })
  );
  useHotkeys(
    Hotkeys.TOGGLE_PLAY,
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (loading) return;
      onTogglePlay();
    },
    {},
    [loading, onTogglePlay]
  );
  useHotkeys(
    Hotkeys.SKIP_BACK,
    () => {
      if (loading) return;

      skip(-1);
    },
    {},
    [loading, skip]
  );
  useHotkeys(
    Hotkeys.SKIP_FORWARD,
    () => {
      if (loading) return;

      skip(1);
    },
    {},
    [loading, skip]
  );
  useHotkeys(
    Hotkeys.RELATED,
    (e) => {
      if (!relatedContent && !relatedContentLegacy) return;

      e.preventDefault();
      e.stopPropagation();

      if (showRelatedContentOverlay) {
        dispatch({
          payload: {},
          type: UiEventTypes.HIDE_RELATED_CONTENT,
        });
      } else {
        dispatch({
          payload: {},
          type: UiEventTypes.SHOW_RELATED_CONTENT,
        });
      }
    },
    {},
    [relatedContent, dispatch, showRelatedContentOverlay, relatedContentLegacy]
  );

  useHotkeys(
    Hotkeys.INCREASE_VOL,
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (loading) return;

      increaseVolume();
    },
    {},
    [loading, increaseVolume]
  );
  useHotkeys(
    Hotkeys.DECREASE_VOL,
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (loading) return;

      decreaseVolume();
    },
    {},
    [loading, decreaseVolume]
  );
  useHotkeys(
    Hotkeys.MUTE,
    () => {
      if (loading) return;

      toggleMute();
    },
    {},
    [loading, toggleMute]
  );
  useHotkeys(
    Hotkeys.CLOSE,
    () => {
      // esc closes fullscreen by default in browsers
      // this always takes priority
      if (fullscreen) return;

      // first close any open modal
      if (modalOptions) {
        dispatch({
          payload: {},
          type: UiEventTypes.HIDE_MODAL,
        });

        return;
      }

      // if the ui is open (due to keyboard navigation)
      // close it before closing the player
      if (uiMenuOpened) {
        dispatch({
          payload: {},
          type: UiEventTypes.HIDE_UI_MENU,
        });

        return;
      }

      // then close related content overlay
      if (showRelatedContentOverlay) {
        dispatch({
          payload: {},
          type: UiEventTypes.HIDE_RELATED_CONTENT,
        });

        return;
      }

      // exit the player
      if (backButtonCallback) {
        backButtonCallback();
      }
    },
    {},
    [modalOptions, backButtonCallback, fullscreen, showRelatedContentOverlay]
  );
  useHotkeys(
    Hotkeys.FULLSCREEN,
    (keyboardEvent: TUnfortunatelyAny) => {
      toggleFullscreen(keyboardEvent as KeyboardEvent);
    },
    {},
    [toggleFullscreen]
  );
  useHotkeys("alt+enter", () => toggleFullscreen(), {}, [toggleFullscreen]);

  // enable when testing/debugging no dead ends
  /* useHotkeys("y", () => {
    dispatch({
      type: UiEventTypes.MINIMIZE_PLAYER_REQUESTED,
      payload: {},
    });
  }); */
};
