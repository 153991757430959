import styled from "@emotion/styled";
import { FC, useEffect, useRef } from "react";

import { TModalOptions } from "../../../@types/modal.types";
import { UiEventTypes } from "../../../constants";
import { ButtonReset } from "../../buttons/base";
import { Button } from "../../buttons/button";
import { ButtonWithIcon } from "../../buttons/button-with-icon";
import { useCustomStyles, useDispatch, useIcon } from "../../context-provider";
import { OverlayWrapper } from "../elements";

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: default;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(16px);
`;

const SmallFlexContainer = styled(FlexContainer)`
  background: rgba(0, 0, 0, 1);
  backdrop-filter: none;
  padding: 5%;
  flex-direction: column;
`;

const InnerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    ". . ."
    ". modal ."
    ". . .";
  width: 100%;
  height: 100%;
`;

const ModalBackground = styled.div<{
  bgColor: string;
  buttonBorderRadius: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 0.5em;
  grid-area: modal;
  padding: 1em;
  display: flex;
  flex-direction: column;
`;

const SmallModalBackground = styled(ModalBackground)`
  display: flex;
  height: 100%;
  padding: 0;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled(ButtonReset)`
  position: relative;
  float: right;
  cursor: pointer;
`;

const SmallClosebutton = styled(ButtonReset)`
  cursor: pointer;
  align-self: flex-end;
`;

const IconWrapper = styled.div`
  height: 1em;
  width: 1em;
  margin-left: 0.9em;
`;

const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 1.4em;
  line-height: 1;
  margin-bottom: 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ModalSubtitle = styled.div`
  font-size: 0.8em;
  line-height: 1;
`;

const SmallModalSubtitle = styled(ModalSubtitle)`
  text-align: center;
  line-height: 1;
`;

const ModalSubtitleFirstRow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.25em;
  line-height: 1;
`;
const ModalSubtitleSecondRow = styled.div`
  color: rgba(255, 255, 255, 0.6);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.5em;
  line-height: 1;
`;

const TitleAndCloseButton = styled.div``;

const ModalBody = styled.p`
  margin-bottom: 0.9em;
  font-size: 0.8em;
  line-height: 1.4;
`;

const ModalBodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;
const SmallModalBody = styled(ModalBody)`
  padding-left: 10%;
  padding-right: 10%;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  gap: 1em;
`;

export type OverlayModalProps = {
  modalOptions: TModalOptions;
};

export const SmallOverlayModal: FC<OverlayModalProps> = ({
  modalOptions: { modalButtons, modalMetadata },
}) => {
  const { buttonBorderRadius } = useCustomStyles();
  const { IconCloseOverlay } = useIcon();
  const dispatch = useDispatch();

  const smallLayoutBackgroundColor = "#000";
  return (
    <OverlayWrapper>
      <SmallFlexContainer>
        <SmallClosebutton
          onClick={() => {
            dispatch({
              payload: {},
              type: UiEventTypes.HIDE_MODAL,
            });
          }}
        >
          <IconWrapper>
            <IconCloseOverlay />
          </IconWrapper>
        </SmallClosebutton>
        <SmallModalBackground
          bgColor={smallLayoutBackgroundColor}
          buttonBorderRadius={buttonBorderRadius}
        >
          <ModalTitle>{modalMetadata.title}</ModalTitle>
          {modalMetadata.subtitle && (
            <SmallModalSubtitle>
              <ModalSubtitleFirstRow>
                {modalMetadata.subtitle.firstRow}
              </ModalSubtitleFirstRow>
              {modalMetadata.subtitle.secondRow && (
                <ModalSubtitleSecondRow>
                  {modalMetadata.subtitle.secondRow}
                </ModalSubtitleSecondRow>
              )}
            </SmallModalSubtitle>
          )}
          <SmallModalBody>{modalMetadata.body}</SmallModalBody>
          <ModalButtonContainer>
            {modalButtons[0].type === "primary" && modalButtons[0].icon ? (
              <ButtonWithIcon
                Icon={modalButtons[0].icon}
                iconBeforeText
                key={`btn-${modalButtons[0].buttonText}`}
                onClick={modalButtons[0].onClick}
                text={modalButtons[0].buttonText}
              />
            ) : (
              <Button
                key={`btn-${modalButtons[0].buttonText}`}
                onClick={modalButtons[0].onClick}
                text={modalButtons[0].buttonText}
              />
            )}
          </ModalButtonContainer>
        </SmallModalBackground>
      </SmallFlexContainer>
    </OverlayWrapper>
  );
};

export const OverlayModal: FC<OverlayModalProps> = ({
  modalOptions: { modalButtons, modalMetadata },
}) => {
  const { brandColor, brandColorDark, buttonBorderRadius } = useCustomStyles();
  const { IconCloseOverlay } = useIcon();
  const dispatch = useDispatch();
  const buttonContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // focus the primary button
    if (buttonContainerRef.current) {
      const focustarget = buttonContainerRef.current
        ?.lastElementChild as HTMLDivElement;
      if (focustarget) {
        focustarget.focus();
      }
    }
  }, []);
  return (
    <OverlayWrapper
      onKeyDown={(e) => {
        if (
          e.key === "ArrowLeft" ||
          e.key === "ArrowRight" ||
          e.key === "ArrowUp" ||
          e.key === "ArrowDown"
        ) {
          e.preventDefault();
          e.stopPropagation();
        }
        if (e.key === "Escape") {
          dispatch({
            payload: {},
            type: UiEventTypes.HIDE_MODAL,
          });
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    >
      <FlexContainer>
        <InnerGrid>
          <ModalBackground
            bgColor={brandColorDark}
            buttonBorderRadius={buttonBorderRadius}
          >
            <TitleAndCloseButton>
              <CloseButton
                onClick={() => {
                  dispatch({
                    payload: {},
                    type: UiEventTypes.HIDE_MODAL,
                  });
                }}
              >
                <IconWrapper>
                  <IconCloseOverlay />
                </IconWrapper>
              </CloseButton>
              <ModalTitle>{modalMetadata.title}</ModalTitle>
            </TitleAndCloseButton>
            <ModalBodyContainer>
              {modalMetadata.subtitle && (
                <ModalSubtitle>
                  <ModalSubtitleFirstRow>
                    {modalMetadata.subtitle.firstRow}
                  </ModalSubtitleFirstRow>
                  {modalMetadata.subtitle.secondRow && (
                    <ModalSubtitleSecondRow>
                      {modalMetadata.subtitle.secondRow}
                    </ModalSubtitleSecondRow>
                  )}
                </ModalSubtitle>
              )}
              <ModalBody>{modalMetadata.body}</ModalBody>
              <ModalButtonContainer ref={buttonContainerRef}>
                {modalButtons.map((button) => {
                  if (button.type === "primary" && button.icon) {
                    // use a different color for primary buttons?
                    return (
                      <ButtonWithIcon
                        background={brandColor}
                        Icon={button.icon}
                        iconBeforeText
                        key={`btn-${button.buttonText}`}
                        onClick={button.onClick}
                        text={button.buttonText}
                      />
                    );
                  }
                  return (
                    <Button
                      key={`btn-${button.buttonText}`}
                      onClick={button.onClick}
                      text={button.buttonText}
                    />
                  );
                })}
              </ModalButtonContainer>
            </ModalBodyContainer>
          </ModalBackground>
        </InnerGrid>
      </FlexContainer>
    </OverlayWrapper>
  );
};
