import styled from "@emotion/styled";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { IconButton } from "../../IconButton";
import { SvgVolume, SvgVolumeOff } from "../../Icons";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare type TCallback = (...args: any) => void;

const throttle = <T extends TCallback>(fn: T, wait: number): T | TCallback => {
  let isCalled = false;

  return (...args) => {
    if (isCalled) return;

    fn(...args);

    isCalled = true;

    setTimeout(() => {
      isCalled = false;
    }, wait);
  };
};

export type TDotAnimationProps = {
  dragging?: boolean;
  ev?: React.PointerEvent<HTMLDivElement>;
  ref: React.RefObject<HTMLDivElement>;
};

const dotAnimation = ({ dragging, ev, ref }: TDotAnimationProps): void => {
  if (!ref.current) return;
  if (!ev) {
    ref.current.style.setProperty("transform", "scale(1)");
    return;
  }
  if (dragging) {
    ref.current.style.setProperty("transform", "scale(1.2)");
    return;
  }
  const { bottom, left, right, top } = ref.current.getBoundingClientRect();
  if (
    ev.clientX > left &&
    ev.clientX < right &&
    ev.clientY < bottom &&
    ev.clientY > top
  ) {
    ref.current.style.setProperty("transition", "transform 0.1s ease-in");
    ref.current.style.setProperty("transform", "scale(1.2)");
  } else {
    ref.current.style.setProperty("transform", "scale(1)");
  }
};
const Bar = styled.div`
  width: 0.25em;
  border-radius: 1em;
`;
const Dot = styled.div`
  margin-top: -0.45em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  background: #fff; // value from design
`;

const VolumeIcon = styled.div`
  position: relative;
  display: inline-flex;
`;

const VolumeBarPaddedArea = styled.div`
  display: flex;
  justify-content: center;
  cursor: default;
  padding-top: 1em;
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
`;

const VolumeClickableArea = styled.div`
  justify-content: center;
  cursor: pointer;
  touch-action: none;
  border-radius: 46px; // values from desgin
  width: 32px; // values from desgin
  height: 178px; // values from desgin
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(40px); //values from desgin
  display: flex;
`;

const VolumeBar = styled(Bar)`
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  position: relative;
  margin: 8px 0;
`;

const VolumeDot = styled(Dot)`
  margin-top: -0.5em;
  position: absolute;
`;

const VolumeBarFilled = styled(Bar)`
  background-color: #fff;
  max-height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const getVolumePercent = (evt: React.PointerEvent<HTMLDivElement>) => {
  const rect = evt.currentTarget.getBoundingClientRect();
  const percent = (rect.top + rect.height - evt.clientY) / rect.height;

  const clamped = Math.min(Math.max(percent, 0), 1);

  if (clamped < 0.05) return 0;
  if (clamped > 0.95) return 1;

  return clamped;
};

export type VolumeControlsProps = {
  changeVolume: TCallback;
  disabled?: boolean;
  isMuted: boolean;
  toggleMute: TCallback;
  volume: number;
};

export const VolumeControls: React.FC<VolumeControlsProps> = ({
  changeVolume,
  disabled,
  isMuted,
  toggleMute,
  volume,
}) => {
  const [localVol, setLocalVol] = useState<number>(1);
  const [isDragging, setIsDragging] = useState(false);
  const volumeDotRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const toggleMuteOnDrag = useCallback(
    (vol: number) => {
      if ((vol > 0 && isMuted) || (vol === 0 && !isMuted)) {
        toggleMute();
      }
    },
    [isMuted, toggleMute]
  );
  const onStartDrag = (ev: React.PointerEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    dotAnimation({ ev, ref: volumeDotRef });
    ev.currentTarget.setPointerCapture(ev.pointerId);

    setIsDragging(true);

    const vol = getVolumePercent(ev);
    setLocalVol(vol);
    toggleMuteOnDrag(vol);
    changeVolume({ saveVolume: vol !== 0, vol });
  };

  const onDragging = throttle((ev: React.PointerEvent<HTMLDivElement>) => {
    dotAnimation({ dragging: isDragging, ev, ref: volumeDotRef });
    if (!isDragging) return;
    ev.preventDefault();

    const vol = getVolumePercent(ev);
    setLocalVol(vol);
    toggleMuteOnDrag(vol);
    changeVolume({ vol });
  }, 100);

  const onStopDrag = (ev: React.PointerEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.currentTarget.setPointerCapture(ev.pointerId);

    setIsDragging(false);
    const vol = getVolumePercent(ev);
    changeVolume({ saveVolume: vol !== 0, vol });
    toggleMuteOnDrag(vol);
  };

  // Update local volume when videoElement emits volumechange
  useEffect(() => {
    if (isDragging) return;

    setLocalVol(volume);
  }, [volume, isDragging]);
  return (
    <VolumeIcon
      aria-label="mute"
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          if (!isVisible) {
            setIsVisible(!isVisible);
          } else if (isVisible) {
            toggleMute();
          }
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onMouseLeave={() => setIsVisible(false)}
      onPointerDown={() => {
        if (disabled) return;

        if (isVisible && volume > 0) {
          toggleMute();
        } else {
          setIsVisible(true);
        }
      }}
      role="button"
      tabIndex={0}
    >
      <IconButton disabled={disabled} nonInteractive={true}>
        {(isMuted || volume === 0) && <SvgVolumeOff />}
        {/* TODO: add different icon */}
        {!isMuted && volume > 0 && <SvgVolume />}
      </IconButton>

      {isVisible && (
        <VolumeBarPaddedArea
          onPointerDown={(e: React.PointerEvent<HTMLDivElement>) =>
            e.stopPropagation()
          }
        >
          <VolumeClickableArea
            // TODO: fix dragging when muted
            onDoubleClick={(e) => e.stopPropagation()}
            onPointerDown={onStartDrag}
            onPointerMove={onDragging}
            onPointerUp={onStopDrag}
          >
            <VolumeBar>
              <VolumeBarFilled
                style={{
                  height: `${isMuted ? 0 : localVol * 100}%`,
                }}
              >
                <VolumeDot
                  aria-label="volume"
                  aria-valuemax={100}
                  aria-valuemin={0}
                  aria-valuenow={localVol * 100}
                  onBlur={() => setIsVisible(false)}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      setIsVisible(false);
                      e.stopPropagation();
                      e.preventDefault();
                    }
                    if (e.key === "ArrowUp") {
                      const newVolume = Math.min((localVol * 100 + 1) / 100, 1);
                      setLocalVol(newVolume);
                      e.stopPropagation();
                      e.preventDefault();
                    }
                    if (e.key === "ArrowDown") {
                      const newVolume = Math.max((localVol * 100 - 1) / 100, 0);
                      setLocalVol(newVolume);
                      e.stopPropagation();
                      e.preventDefault();
                    }
                    if (e.key === "Enter" || e.key === " ") {
                      changeVolume({
                        saveVolume: localVol !== 0,
                        vol: localVol,
                      });
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                  ref={volumeDotRef}
                  role="slider"
                  tabIndex={0}
                />
              </VolumeBarFilled>
            </VolumeBar>
          </VolumeClickableArea>
        </VolumeBarPaddedArea>
      )}
    </VolumeIcon>
  );
};
