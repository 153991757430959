import { Dispatch, useCallback, useEffect, useRef } from "react";

import { usePlayerState } from "../components/context-provider";
import { UiEventTypes } from "../constants";
import { TAction } from "./ui-state-reducer.hook";

export const useUiTimeout = (dispatch: Dispatch<TAction>): (() => void) => {
  const hideTimeoutRef = useRef(-1);

  const { uiMenuOpened } = usePlayerState();
  const menuOpenedRef = useRef<boolean>();

  useEffect(() => {
    menuOpenedRef.current = uiMenuOpened;
  }, [uiMenuOpened]);

  useEffect(() => () => clearTimeout(hideTimeoutRef.current), []);

  return useCallback(() => {
    dispatch({
      payload: {},
      type: UiEventTypes.SHOW_UI,
    });

    clearTimeout(hideTimeoutRef.current);
    hideTimeoutRef.current = window.setTimeout(() => {
      // don't hide the UI if we have a selection menu opened (language, recordings)
      if (menuOpenedRef.current) return;
      dispatch({ payload: {}, type: UiEventTypes.HIDE_UI });
    }, 3000);
  }, [dispatch]);
};
