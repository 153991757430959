import styled from "@emotion/styled";
import { TCallback } from "@telia-company/tv.web-player-shared";
import { FC, PointerEvent, RefObject, useEffect, useState } from "react";

import { TPlayerIcon } from "../@types/icon-type";
import { TChangeVolumeArgs } from "../@types/types";
import { UiEventTypes } from "../constants";
import { HotkeyTooltips } from "../hooks/global-hotkeys.hook";
import { dotAnimation, throttle } from "../utils";
import { useDispatch } from "./context-provider";
import { Bar, Dot, GridIcon } from "./elements";
import { animatedHidden, animatedVisible } from "./styles";

const VolumeIconWrapper = styled(GridIcon)<{
  isActive: boolean;
  smallLayout: boolean;
}>`
  grid-column: ${({ smallLayout }) => (smallLayout ? "1 / 3" : "15")};
  grid-row: ${({ smallLayout }) => (smallLayout ? "8 / -1" : "9")};
  position: relative;

  > div {
    display: block;
    ${({ isActive }) => (isActive ? `${animatedVisible}` : `${animatedHidden}`)}
  }

  &:hover > div {
    ${animatedVisible}
  }
`;

const VolumeBarPaddedArea = styled.div`
  cursor: default;
  padding-top: 1em;
  position: absolute;
  left: 0;
  bottom: 100%;
  display: flex;
  width: 100%;
`;

const VolumeClickableArea = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 6em;
  touch-action: none;
`;

const VolumeBar = styled(Bar)`
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  position: relative;
`;

const VolumeDot = styled(Dot)`
  margin-top: -0.5em;
  position: absolute;
`;

const VolumeBarFilled = styled(Bar)`
  background-color: #fff;
  max-height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export type TVolumeButtonProps = {
  changeVolume: (args: TChangeVolumeArgs) => void;
  muted: boolean;
  MutedIcon: TPlayerIcon;
  onMouseEnter: TCallback;
  onMouseLeave: TCallback;
  onToggleMute: TCallback;
  smallLayout: boolean;
  volume: number;
  volumeDotRef: RefObject<HTMLDivElement>;
  VolumeIcon: TPlayerIcon;
  VolumeLowIcon: TPlayerIcon;
};

const getVolumePercent = (evt: PointerEvent<HTMLDivElement>) => {
  const rect = evt.currentTarget.getBoundingClientRect();
  const percent = (rect.top + rect.height - evt.clientY) / rect.height;

  const clamped = Math.min(Math.max(percent, 0), 1);

  if (clamped < 0.05) return 0;
  if (clamped > 0.95) return 1;

  return clamped;
};

export const VolumeButton: FC<TVolumeButtonProps> = ({
  changeVolume,
  muted,
  MutedIcon,
  onMouseEnter,
  onMouseLeave,
  onToggleMute,
  smallLayout,
  volume,
  volumeDotRef,
  VolumeIcon,
  VolumeLowIcon,
}) => {
  const [localVol, setLocalVol] = useState<number>(1);
  const [isDragging, setIsDragging] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();

  const onStartDrag = (ev: PointerEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    dotAnimation({ ev, ref: volumeDotRef });
    ev.currentTarget.setPointerCapture(ev.pointerId);

    setIsDragging(true);

    const vol = getVolumePercent(ev);
    setLocalVol(vol);
    changeVolume({ saveVolume: vol !== 0, vol });
  };

  const onDragging = throttle((ev: PointerEvent<HTMLDivElement>) => {
    dotAnimation({ dragging: isDragging, ev, ref: volumeDotRef });
    if (!isDragging) return;
    ev.preventDefault();

    const vol = getVolumePercent(ev);
    setLocalVol(vol);
    changeVolume({ vol });
  }, 100);

  const onStopDrag = (ev: PointerEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.currentTarget.setPointerCapture(ev.pointerId);

    setIsDragging(false);
    const vol = getVolumePercent(ev);
    changeVolume({ saveVolume: vol !== 0, vol });
  };

  // Update local volume when videoElement emits volumechange
  useEffect(() => {
    if (isDragging) return;

    setLocalVol(volume);
  }, [volume, isDragging]);

  useEffect(() => {
    if (isActive) {
      dispatch({
        payload: {},
        type: UiEventTypes.SHOW_UI_MENU,
      });
    } else {
      dispatch({
        payload: {},
        type: UiEventTypes.HIDE_UI_MENU,
      });
    }
  }, [dispatch, isActive]);

  return (
    <VolumeIconWrapper
      aria-label={`mute (${HotkeyTooltips.MUTE.toUpperCase()})`}
      isActive={isActive}
      onBlur={(e) => {
        if (!e.target.contains(e.relatedTarget)) {
          setIsActive(false);
          onMouseLeave();
        }
      }}
      onFocus={() => {
        setIsActive(true);
      }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          setIsActive(false);
          onMouseLeave();
          e.currentTarget.focus();
          e.stopPropagation();
          e.preventDefault();
        }
        if (e.key === " " || e.key === "Enter") {
          onToggleMute();
          onMouseEnter();
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onPointerDown={onToggleMute}
      smallLayout={smallLayout}
      tabIndex={0}
    >
      {(muted || volume === 0) && <MutedIcon />}
      {!muted && volume >= 0.5 && <VolumeIcon />}
      {!muted && volume < 0.5 && volume > 0 && <VolumeLowIcon />}

      <VolumeBarPaddedArea
        onPointerDown={(e: PointerEvent<HTMLDivElement>) => e.stopPropagation()}
      >
        <VolumeClickableArea
          onDoubleClick={(e) => e.stopPropagation()}
          onPointerDown={onStartDrag}
          onPointerMove={onDragging}
          onPointerUp={onStopDrag}
        >
          <VolumeBar>
            <VolumeBarFilled
              style={{
                height: `${muted ? 0 : localVol * 100}%`,
              }}
            >
              <VolumeDot ref={volumeDotRef} tabIndex={0} />
            </VolumeBarFilled>
          </VolumeBar>
        </VolumeClickableArea>
      </VolumeBarPaddedArea>
    </VolumeIconWrapper>
  );
};
