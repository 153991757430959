import {
  ErrorCategories,
  INormalizedError,
  PlaybackEventTypes,
  StoppedReasons,
  TPlaybackEvent,
} from "@telia-company/tv.web-playback-sdk";
import {
  isSafari,
  PlayerControls,
  TAudioTrack,
  TContentMetadata,
  TPlaybackConfig,
  TPlayerEvent,
  TPlayerStats,
  TTextTrack,
  TTimeline,
  VideoIdType,
  WatchMode,
  WebPlayerEventType,
} from "@telia-company/tv.web-player-shared";
import { Dispatch, useReducer } from "react";

import { TModalOptions } from "../@types/modal.types";
import {
  TAudioListItems,
  TPlaybackType,
  TTextListItems,
  TUiEvent,
} from "../@types/types";
import { TRelatedContentImplementation } from "../components/overlays/list-overlay/types/types";
import { EngineState, PlaybackState, UiEventTypes } from "../constants";
import { getPlaybackType } from "../utils";

export type TAction = TPlaybackEvent | TPlayerEvent | TUiEvent;

export type TSeekRange = {
  end: number;
  start: number;
};

export type TState = {
  adMarkers: null | number[];
  atLiveEdge: boolean;
  audioTracks: TAudioListItems;
  autoplayBlocked: boolean | null;
  buffering: boolean;
  cast: "CASTING" | "WAITING" | null;
  controls: PlayerControls | undefined;
  currentTime: null | number;
  droppedFrames: null | number;
  duration: null | number;
  engineState: EngineState;
  error: INormalizedError | null;
  fetchingMetadata: boolean;
  fullscreen: boolean;
  isInAdBreak: boolean;
  isLive: boolean | null;
  isRecording: boolean;
  liveSeekEnabled: boolean;
  loading: boolean;
  manifestChanged: boolean;
  metadata: TContentMetadata;
  minimized: boolean;
  minimizeRequested: boolean;
  modalOptions: null | TModalOptions;
  muted: boolean;
  nextEpisodeOverlayDuration: number | undefined;
  paused: boolean;
  playback: null | TPlaybackConfig;
  playbackState: PlaybackState;
  playbackType: null | TPlaybackType;
  recordingSpinner: boolean;
  relatedContent: null | TRelatedContentImplementation;
  restarting: boolean;
  seekIndicatorVisible: boolean;
  seeking: boolean;
  seekRange?: TSeekRange;
  selectedAudioTrack: null | TAudioTrack;
  selectedTextTrack: null | TTextTrack;
  shouldFetchMetadata: boolean;
  showInactivityWarning: boolean;
  showNextEpisodeOverlay: boolean;
  showProgressBar: boolean;
  showRecordingMenu: boolean;
  showRelatedContentOverlay: boolean;
  showStatistics: boolean;
  showTimelineActionButton: boolean;
  stats?: TPlayerStats;
  subtitlesOff: boolean;
  textElement: HTMLDivElement | undefined;
  textTracks: TTextListItems;
  textTrackVisible: boolean;
  timeline: TTimeline;
  toastMessage: null | string;
  trickPlayRestrictions: {
    noFastForward?: boolean;
    noPause?: boolean;
    noRewind?: boolean;
  };
  uiMenuOpened: boolean;
  uiSpinner: boolean;
  uiVisible: boolean;
  unmodifiedSeekRange?: TSeekRange;
  videoElement: HTMLVideoElement | undefined;
  volume: number;
  watchCredits: boolean;
};

export const initialState: TState = {
  adMarkers: null,
  atLiveEdge: false,
  audioTracks: [],
  autoplayBlocked: null,
  buffering: false,
  cast: null,
  controls: undefined,
  currentTime: null,
  droppedFrames: null,
  duration: null,
  engineState: EngineState.LOADING,
  error: null,
  fetchingMetadata: false,
  fullscreen: false,
  isInAdBreak: false,
  isLive: null,
  isRecording: false,
  liveSeekEnabled: false,
  loading: true,
  manifestChanged: false,
  metadata: {
    title: "",
  },
  minimized: false,
  minimizeRequested: false,
  modalOptions: null,
  muted: false,
  nextEpisodeOverlayDuration: undefined,
  paused: true,
  playback: null,
  playbackState: PlaybackState.PAUSED,
  playbackType: null,
  recordingSpinner: false,
  relatedContent: null,
  restarting: false,
  seekIndicatorVisible: false,
  seeking: false,
  selectedAudioTrack: null,
  selectedTextTrack: null,
  shouldFetchMetadata: false,
  showInactivityWarning: false,
  showNextEpisodeOverlay: false,
  showProgressBar: true,
  showRecordingMenu: false,
  showRelatedContentOverlay: false,
  showStatistics: false,
  showTimelineActionButton: false,
  subtitlesOff: true,
  textElement: undefined,
  textTracks: [],
  textTrackVisible: false,
  timeline: null,
  toastMessage: null,
  trickPlayRestrictions: {
    noFastForward: false,
    noPause: false,
    noRewind: false,
  },
  uiMenuOpened: false,
  uiSpinner: false,
  uiVisible: false,
  videoElement: undefined,
  volume: 1,
  watchCredits: false,
};

const reducer = (state: TState, action: TAction): TState => {
  // Good for debugging
  // if (
  //   action.type !== PlaybackEventTypes.TIME_UPDATE
  //   && action.type !== PlaybackEventTypes.BITRATE_CHANGED
  //   && action.type !== PlaybackEventTypes.ADVERTISEMENT_TIME_UPDATE
  //   && action.type !== WebPlayerEventType.SEEK_RANGE_UPDATE
  //   && action.type !== UiEventTypes.SHOW_UI
  //   && action.type !== UiEventTypes.HIDE_UI
  //   && action.type !== PlaybackEventTypes.CDN_CHANGED
  // ) {
  //   console.log("==> reducer", action.type);
  // }
  switch (action.type) {
    case PlaybackEventTypes.ADVERTISEMENT_BREAK_ENDED:
      return {
        ...state,
        isInAdBreak: false,
      };
    case PlaybackEventTypes.ADVERTISEMENT_BREAK_STARTED:
      return {
        ...state,
        isInAdBreak: true,
      };
    case PlaybackEventTypes.AUDIO_TRACK_CHANGED:
      if (
        state.selectedAudioTrack?.isoCode === action.payload.language &&
        state.selectedAudioTrack.type === action.payload.type
      )
        return state;

      return {
        ...state,
        selectedAudioTrack: {
          isoCode: action.payload.language,
          type: action.payload.type,
        },
      };
    case PlaybackEventTypes.BUFFERED:
      return {
        ...state,
        buffering: false,
        playbackState: state.paused
          ? PlaybackState.PAUSED
          : PlaybackState.PLAYING,
      };
    case PlaybackEventTypes.BUFFERING:
      return {
        ...state,
        buffering: true,
        playbackState: PlaybackState.BUFFERING,
      };
    case PlaybackEventTypes.ERROR:
      return {
        ...state,
        engineState: EngineState.STOPPED,
        error: action.payload.error,
        paused: true,
        playbackState: PlaybackState.PAUSED,
      };
    case PlaybackEventTypes.LOADED:
      return {
        ...state,
        engineState: EngineState.READY,
        loading: false,
      };
    case PlaybackEventTypes.LOADING:
      return {
        ...state,
        engineState: EngineState.LOADING,
        error: null,
        loading: true,
      };
    case PlaybackEventTypes.MANIFEST_TYPE_CHANGED:
      return {
        ...state,
        manifestChanged: true,
      };
    case PlaybackEventTypes.PAUSED:
      return {
        ...state,
        paused: true,
        playbackState: PlaybackState.PAUSED,
      };
    case PlaybackEventTypes.PLAY:
      return {
        ...state,
        paused: false,
      };
    case PlaybackEventTypes.PLAYING:
      return {
        ...state,
        autoplayBlocked: false,
        paused: false,
        playbackState: PlaybackState.PLAYING,
      };
    case PlaybackEventTypes.SEEKED:
      return {
        ...state,
        playbackState: state.paused
          ? PlaybackState.PAUSED
          : PlaybackState.PLAYING,
        seeking: false,
        watchCredits: false,
      };
    case PlaybackEventTypes.SEEKING:
      return {
        ...state,
        playbackState: PlaybackState.SEEKING,
        seeking: true,
      };
    case PlaybackEventTypes.STARTING:
      return {
        ...state,
        error: null,
      };
    case PlaybackEventTypes.STOPPED: {
      const { reason } = action.payload;

      const handleStoppedReason = () => {
        // do not overwrite existing errors
        if (state.error) return state.error;

        switch (reason) {
          case StoppedReasons.CONCURRENT_STREAMS: {
            return {
              category: ErrorCategories.DEFAULT,
              code: "CONCURRENT_STREAMS",
              details: {
                metadata: {
                  channelLogo: state.metadata.logo,
                  currentTime: state.currentTime,
                  duration: state.duration,
                  endTime: state.metadata.endTime,
                  episode: state.metadata.episode,
                  images: state.metadata.images,
                  season: state.metadata.season,
                  startTime: state.metadata.startTime,
                  title: state.metadata.seriesTitle || state.metadata.title,
                },
              },
              fatal: true,
              message: "Concurrent streams detected.",
            };
          }
          case StoppedReasons.INACTIVITY: {
            return {
              category: ErrorCategories.DEFAULT,
              code: "STALE_SESSION",
              details: {},
              fatal: true,
              message: "User inactivity detected.",
            };
          }
          default:
            return state.error;
        }
      };

      const error: INormalizedError | null = handleStoppedReason();

      return {
        ...initialState,
        engineState: state.restarting
          ? EngineState.LOADING
          : EngineState.STOPPED,
        error,
        fullscreen: state.restarting
          ? state.fullscreen
          : initialState.fullscreen,
        minimizeRequested: false,
        // preserve playback through a restart
        playback: state.restarting ? state.playback : null,
        restarting: false,
        uiVisible: state.restarting ? state.uiVisible : initialState.uiVisible,
      };
    }
    case PlaybackEventTypes.TEXT_TRACK_CHANGED:
      if (
        state.selectedTextTrack?.isoCode === action.payload.language &&
        state.selectedTextTrack.type === action.payload.type
      )
        return state;

      return {
        ...state,
        selectedTextTrack: {
          isoCode: action.payload.language,
          type: action.payload.type,
        },
      };
    case PlaybackEventTypes.TEXT_TRACK_VISIBILITY_CHANGED:
      if (state.textTrackVisible === action.payload.visible) return state;

      return {
        ...state,
        textTrackVisible: action.payload.visible,
      };
    case PlaybackEventTypes.TIME_UPDATE: {
      const { currentTime, duration } = action.payload;

      if (
        state.timeline?.closingCredits &&
        !state.timeline.closingCredits.patched
      ) {
        /**
         * workaround for bad timeline metadata,
         * make sure we always have 20 seconds at the end
         * to show our overlay
         */
        const patchedTimeline: TTimeline = { ...state.timeline };

        // make sure we have enough time to present the next episode overlay
        // INC-12160
        if (
          (state.duration &&
            state.timeline.closingCredits.end > state.duration) ||
          (state.duration &&
            state.duration - state.timeline.closingCredits.start < 10)
        ) {
          patchedTimeline.closingCredits = {
            end: duration,
            patched: true,
            start: duration - 20,
            type: "CLOSING_CREDITS",
          };
        } else if (state.duration && state.timeline?.closingCredits) {
          // no need to patch
          patchedTimeline.closingCredits = {
            end: state.timeline.closingCredits.end,
            patched: true,
            start: state.timeline.closingCredits.start,
            type: state.timeline.closingCredits.type,
          };
        }
        return {
          ...state,
          currentTime: currentTime || null,
          duration: duration || null,
          timeline: patchedTimeline,
        };
      }

      // if performance becomes a concern, move TIMEUPDATE-data into a separate state. Currently not an issue.
      return {
        ...state,
        currentTime: currentTime || null,
        duration: duration || null,
      };
    }
    case PlaybackEventTypes.VOLUME_CHANGED: {
      const { muted, volume } = action.payload;
      if (!(typeof volume === "number" && typeof muted === "boolean"))
        return state;

      if (state.volume === volume && state.muted === muted) return state;

      return {
        ...state,
        muted,
        volume,
      };
    }
    case UiEventTypes.CANCEL_LOADING_MODE:
      return {
        ...state,
        loading: false,
      };
    case UiEventTypes.CAST_CANCELLED:
      return {
        ...state,
        cast: null,
      };
    case UiEventTypes.CAST_INITIATED:
      return {
        ...state,
        cast: "WAITING",
      };
    case UiEventTypes.CONTENT_SPEC_UPDATED:
      return {
        ...state,
        ...action.payload,
        // if state previously has a playback config it indicates player should restart
        error: null,
        loading: true,
        // as it's not the first video playing in this player instance
        restarting: !!state.playback,
      };
    case UiEventTypes.ENTER_FULLSCREEN:
      if (state.fullscreen) return state;

      return {
        ...state,
        fullscreen: true,
      };
    case UiEventTypes.ENTER_LOADING_MODE:
      return {
        ...state,
        loading: true,
        modalOptions: null,
        showRelatedContentOverlay: false,
      };
    case UiEventTypes.EXIT_FULLSCREEN:
      if (!state.fullscreen) return state;

      return {
        ...state,
        fullscreen: false,
      };
    case UiEventTypes.FETCHING_METADATA:
      return {
        ...state,
        fetchingMetadata: true,
      };
    case UiEventTypes.FETCHING_METADATA_FAILED:
      return {
        ...state,
        fetchingMetadata: false,
      };
    case UiEventTypes.HIDE_MODAL:
      return {
        ...state,
        modalOptions: null,
      };
    case UiEventTypes.HIDE_NEXT_EPISODE:
      return {
        ...state,
        showNextEpisodeOverlay: false,
      };
    case UiEventTypes.HIDE_PROGRESS_BAR:
      if (!state.showProgressBar) return state;

      return {
        ...state,
        showProgressBar: false,
      };
    case UiEventTypes.HIDE_RECORDING_MENU:
      return {
        ...state,
        showRecordingMenu: false,
      };
    case UiEventTypes.HIDE_RECORDING_SPINNER:
      return {
        ...state,
        recordingSpinner: false,
      };
    case UiEventTypes.HIDE_RELATED_CONTENT:
      return {
        ...state,
        showRelatedContentOverlay: false,
      };
    case UiEventTypes.HIDE_SEEK_INDICATOR:
      return {
        ...state,
        seekIndicatorVisible: false,
      };
    case UiEventTypes.HIDE_TOAST:
      return {
        ...state,
        toastMessage: null,
      };
    case UiEventTypes.HIDE_UI:
      if (!state.uiVisible) return state;

      return {
        ...state,
        uiVisible: false,
      };
    case UiEventTypes.HIDE_UI_MENU:
      return {
        ...state,
        uiMenuOpened: false,
      };
    case UiEventTypes.HIDE_UI_SPINNER:
      return {
        ...state,
        uiSpinner: false,
      };
    case UiEventTypes.METADATA:
      return {
        ...state,
        fetchingMetadata: false,
        metadata: action.payload.metadata || state.metadata,
        shouldFetchMetadata: false,
      };
    case UiEventTypes.MINIMIZE_PLAYER_REQUESTED:
      return {
        ...state,
        minimizeRequested: action.payload.minimizeRequested,
      };
    case UiEventTypes.RELATED_CONTENT_UPDATE:
      return {
        ...state,
        relatedContent: action.payload,
      };
    case UiEventTypes.SHOULD_FETCH_METADATA:
      return {
        ...state,
        shouldFetchMetadata: !state.fetchingMetadata,
      };
    case UiEventTypes.SHOW_MODAL:
      return {
        ...state,
        modalOptions: action.payload.modalOptions,
      };
    case UiEventTypes.SHOW_NEXT_EPISODE:
      return {
        ...state,
        nextEpisodeOverlayDuration: action.payload.nextEpisodeOverlayDuration,
        showNextEpisodeOverlay: !state.watchCredits,
      };
    case UiEventTypes.SHOW_PROGRESS_BAR:
      if (state.showProgressBar) return state;

      return {
        ...state,
        showProgressBar: true,
      };
    case UiEventTypes.SHOW_RECORDING_MENU:
      return {
        ...state,
        showRecordingMenu: true,
      };
    case UiEventTypes.SHOW_RECORDING_SPINNER:
      return {
        ...state,
        recordingSpinner: true,
      };
    case UiEventTypes.SHOW_RELATED_CONTENT:
      return {
        ...state,
        showRelatedContentOverlay: true,
      };
    case UiEventTypes.SHOW_SEEK_INDICATOR:
      return {
        ...state,
        seekIndicatorVisible: true,
      };
    case UiEventTypes.SHOW_TOAST:
      return {
        ...state,
        toastMessage: action.payload.message,
      };
    case UiEventTypes.SHOW_UI:
      if (state.uiVisible || state.modalOptions) return state;

      return {
        ...state,
        uiVisible: true,
      };
    case UiEventTypes.SHOW_UI_MENU:
      return {
        ...state,
        uiMenuOpened: true,
      };
    case UiEventTypes.SHOW_UI_SPINNER:
      return {
        ...state,
        uiSpinner: true,
      };
    case UiEventTypes.TOGGLE_STATISTICS:
      return {
        ...state,
        showStatistics: !state.showStatistics,
      };
    case UiEventTypes.WATCH_CREDITS:
      return {
        ...state,
        showNextEpisodeOverlay: false,
        watchCredits: true,
      };
    case WebPlayerEventType.AD_MARKERS:
      return {
        ...state,
        adMarkers: action.payload,
      };
    case WebPlayerEventType.AUTOPLAY_BLOCKED:
      // if we've already started playing, this is not autoplay related.
      if (state.autoplayBlocked !== null) return state;

      return {
        ...state,
        autoplayBlocked: true,
        paused: true,
        playbackState: PlaybackState.PAUSED,
      };
    case WebPlayerEventType.CONTROLS_AVAILABLE:
      return {
        ...state,
        controls: action.payload,
      };
    case WebPlayerEventType.END_OF_STREAM:
      return {
        ...initialState,
        engineState: EngineState.STOPPED,
      };
    case WebPlayerEventType.INITIAL_ACTIVE_TRACKS: {
      const { audio, text } = action.payload.initialActiveTracks;

      return {
        ...state,
        selectedAudioTrack: audio || state.selectedAudioTrack,
        selectedTextTrack: text || state.selectedTextTrack,
        textTrackVisible: action.payload.textTrackVisible,
      };
    }
    case WebPlayerEventType.IS_LIVE: {
      const { isLive } = action.payload;
      const { playback } = state;

      return {
        ...state,
        isLive,
        playbackType: getPlaybackType(playback, isLive),
        shouldFetchMetadata: true,
      };
    }
    case WebPlayerEventType.LANGUAGES: {
      const { audioTracks, textTracks } = action.payload;

      return {
        ...state,
        audioTracks: audioTracks || state.audioTracks,
        textTracks: textTracks || state.textTracks,
      };
    }
    case WebPlayerEventType.PLAYBACK_RESTRICTIONS: {
      const { noFastForward, noPause, noRewind } =
        action.payload.trickPlayRestrictions;
      return {
        ...state,
        liveSeekEnabled:
          ((state.playback?.playbackSpec.videoIdType === VideoIdType.MEDIA &&
            state.playback.playbackSpec.watchMode === WatchMode.LIVE) ||
            state.playback?.playbackSpec.videoIdType === VideoIdType.CHANNEL ||
            (state.playback?.playbackSpec.videoIdType === VideoIdType.MEDIA &&
              state.playback.playbackSpec.watchMode === WatchMode.STARTOVER)) &&
          !(noPause || noRewind || noFastForward),
        trickPlayRestrictions:
          action.payload.trickPlayRestrictions || state.trickPlayRestrictions,
      };
    }
    case WebPlayerEventType.SEEK_RANGE_UPDATE: {
      const { seekRange, stats } = action.payload;
      // keep track of the original seekRange, to use when fetching metadata for
      const unmodifiedSeekRange = seekRange;
      // to avoid seek window "jumping around" before we have metadata
      if (
        state.playback?.playbackSpec.videoIdType === VideoIdType.CHANNEL &&
        (!state.metadata.startTime || !state.metadata.endTime)
      )
        return state;

      let modifiedSeekRange;

      if (
        state.liveSeekEnabled &&
        state.metadata.startTime &&
        state.metadata.endTime
      ) {
        // rework seekrange to be limited to current programme (business decision)
        const programmeElapsedTime =
          (Date.now() - state.metadata.startTime) / 1000;
        const programmeDuration =
          (state.metadata.endTime - state.metadata.startTime) / 1000;

        const isOngoing =
          Date.now() > state.metadata.startTime &&
          Date.now() < state.metadata.endTime;

        // if the current programme is ongoing, use seekRange.end, otherwise calculate how much
        // to remove from seekRange.end
        const newEnd = isOngoing
          ? seekRange.end
          : seekRange.end - (Date.now() - state.metadata.endTime) / 1000;

        // limit the start of the seekable range to the current programme
        const newStart = isOngoing
          ? newEnd - programmeElapsedTime
          : newEnd - programmeDuration;

        modifiedSeekRange = {
          ...seekRange,
          end: newEnd,
          start: newStart,
        };
      }

      // Safari doesn't play as close to the end, for some reason
      const liveEdgeThreshold = isSafari ? 15 : 10;

      // calculate live edge here since SEEK_RANGE_UPDATE is triggered
      // on an interval. It works if the stream is paused, unlike
      // TIME_UPDATE.
      const atLiveEdge =
        (state.playbackType === "live-event" ||
          state.playbackType === "startover" ||
          state.playbackType === "linear-channel") &&
        seekRange.end &&
        state.currentTime
          ? seekRange.end - state.currentTime < liveEdgeThreshold
          : false;

      return {
        ...state,
        atLiveEdge,
        seekRange:
          state.liveSeekEnabled && modifiedSeekRange
            ? modifiedSeekRange
            : unmodifiedSeekRange,
        stats,
        unmodifiedSeekRange,
      };
    }
    case WebPlayerEventType.TIMELINE_DATA: {
      const {
        payload: { timeline },
      } = action;
      const closingCredits = timeline.find(
        (block) => block.type === "CLOSING_CREDITS"
      );
      const recap = timeline.find((block) => block.type === "RECAP");
      const openingCredits = timeline.find(
        (block) => block.type === "OPENING_CREDITS"
      );

      const parsedTimeline = {
        closingCredits: closingCredits
          ? {
              end: (closingCredits.offset + closingCredits.duration) / 1000,
              start: closingCredits.offset / 1000,
              type: "CLOSING_CREDITS",
            }
          : undefined,
        openingCredits: openingCredits
          ? {
              end: (openingCredits.offset + openingCredits.duration) / 1000,
              start: openingCredits.offset / 1000,
              type: "OPENING_CREDITS",
            }
          : undefined,
        recap: recap
          ? {
              end: (recap.offset + recap.duration) / 1000,
              start: recap.offset / 1000,
              type: "RECAP",
            }
          : undefined,
      };

      return {
        ...state,
        timeline: parsedTimeline,
      };
    }
    case WebPlayerEventType.USER_INACTIVITY_WARNING:
      // can't reset the warning in the vod case, since it needs to be dismissed
      // by clicking the button
      return {
        ...state,
        showInactivityWarning: action.payload.visible,
      };
    case WebPlayerEventType.VIDEO_ELEMENT_READY:
      return {
        ...state,
        muted: action.payload.videoElement.muted,
        textElement: action.payload.textElement,
        videoElement: action.payload.videoElement,
        volume: action.payload.videoElement.volume,
      };
    default:
      return state;
  }
};

export const useUiStateReducer = (): [TState, Dispatch<TAction>] =>
  useReducer(reducer, initialState);
