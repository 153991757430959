export const playerUiVersion = WEB_PLAYER_UI_VERSION;

export enum EngineState {
  LOADING = "LOADING",
  READY = "READY",
  STOPPED = "STOPPED",
}

export enum PlaybackState {
  BUFFERING = "BUFFERING",
  PAUSED = "PAUSED",
  PLAYING = "PLAYING",
  SEEKING = "SEEKING",
}

export enum UiEventTypes {
  CANCEL_LOADING_MODE = "CANCEL_LOADING_MODE",
  CAST_CANCELLED = "CAST_CANCELLED",
  CAST_INITIATED = "CAST_INITIATED",
  CONTENT_SPEC_UPDATED = "CONTENT_SPEC_UPDATED",
  ENTER_FULLSCREEN = "ENTER_FULLSCREEN",
  ENTER_LOADING_MODE = "ENTER_LOADING_MODE",
  EXIT_FULLSCREEN = "EXIT_FULLSCREEN",
  FETCHING_METADATA = "FETCHING_METADATA",
  FETCHING_METADATA_FAILED = "FETCHING_METADATA_FAILED",
  HIDE_MODAL = "HIDE_MODAL",
  HIDE_NEXT_EPISODE = "HIDE_NEXT_EPISODE",
  HIDE_PROGRESS_BAR = "HIDE_PROGRESS_BAR",
  HIDE_RECORDING_MENU = "HIDE_RECORDING_MENU",
  HIDE_RECORDING_SPINNER = "HIDE_RECORDING_SPINNER",
  HIDE_RELATED_CONTENT = "HIDE_RELATED_CONTENT",
  HIDE_SEEK_INDICATOR = "HIDE_SEEK_INDICATOR",
  HIDE_TOAST = "HIDE_TOAST",
  HIDE_UI = "HIDE_UI",
  HIDE_UI_MENU = "HIDE_UI_MENU",
  HIDE_UI_SPINNER = "HIDE_UI_SPINNER",
  METADATA = "METADATA",
  MINIMIZE_PLAYER_REQUESTED = "MINIMIZE_PLAYER_REQUESTED",
  RELATED_CONTENT_UPDATE = "RELATED_CONTENT_UPDATE",
  SHOULD_FETCH_METADATA = "SHOULD_FETCH_METADATA",
  SHOW_MODAL = "SHOW_MODAL",
  SHOW_NEXT_EPISODE = "SHOW_NEXT_EPISODE",
  SHOW_PROGRESS_BAR = "SHOW_PROGRESS_BAR",
  SHOW_RECORDING_MENU = "SHOW_RECORDING_MENU",
  SHOW_RECORDING_SPINNER = "SHOW_RECORDING_SPINNER",
  SHOW_RELATED_CONTENT = "SHOW_RELATED_CONTENT",
  SHOW_SEEK_INDICATOR = "SHOW_SEEK_INDICATOR",
  SHOW_TOAST = "SHOW_TOAST",
  SHOW_UI = "SHOW_UI",
  SHOW_UI_MENU = "SHOW_UI_MENU",
  SHOW_UI_SPINNER = "SHOW_UI_SPINNER",
  TOGGLE_STATISTICS = "TOGGLE_STATISTICS",
  WATCH_CREDITS = "WATCH_CREDITS",
}

export const separator = "•";
