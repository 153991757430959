import styled from "@emotion/styled";
import {
  TAudioTrackType,
  TTextTrackType,
} from "@telia-company/tv.web-playback-sdk";
import {
  TAudioTrack,
  TCallback,
  TLanguageCode,
  TTextTrack,
} from "@telia-company/tv.web-player-shared";
import { FC, useCallback, useEffect, useRef, useState } from "react";

import { TPlayerIcon } from "../@types/icon-type";
import { TLanguageButtonProps } from "../@types/types";
import { UiEventTypes } from "../constants";
import {
  useClickHandler,
  useCustomStyles,
  useDispatch,
  useIcon,
  useTranslations,
} from "./context-provider";
import { GridIcon } from "./elements";
import { animatedHidden, animatedVisible } from "./styles";

const LanguageIconWrapper = styled(GridIcon)<{ borderRadius: string }>`
  grid-column: 1;

  border-bottom-left-radius: ${({ borderRadius }) => borderRadius || "0.5em"};
  border-top-left-radius: ${({ borderRadius }) => borderRadius || "0.5em"};

  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
`;

/* const LanguageText = styled.div`
  grid-column: 2 / 4;
  line-height: 1;
  font-size: 0.9em;
  height: 100%;
`; */

/* const TextBgWrapper = styled.div<{ borderRadius: string }>`
  height: 100%;
  border-bottom-right-radius: ${({ borderRadius }) => borderRadius || "0.5em"};
  border-top-right-radius: ${({ borderRadius }) => borderRadius || "0.5em"};

  display: flex;
  align-items: center;
  max-width: max-content;
`; */

/* const TextInnerWrapper = styled.span`
  padding: 0 1em 0 0;
`; */

const LanguagePopup = styled.div`
  cursor: default;
  flex-shrink: 0;

  position: absolute;
  margin-right: 2em;
  bottom: 100%;

  padding: 0 0 1em;
`;

const LanguageSelector = styled.div<{ isActive: boolean; position: string }>`
  cursor: pointer;

  ${({ position }) => `
    grid-column: ${position};
    grid-row: 9;
  `}

  display: grid;

  align-items: center;
  justify-content: center;

  position: relative;

  ${LanguagePopup} {
    display: grid;
    ${animatedHidden}
  }

  &:hover {
    ${LanguagePopup} {
      ${animatedVisible}
    }

    svg {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
  ${({ isActive }) =>
    isActive
      ? `
    ${LanguagePopup} {
      ${animatedVisible}
    }
  `
      : ``}
    

    svg {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
`;

export type TLanguagePopupProps = {
  audioTracks: Array<TAudioTrack>;
  onBlurCallback: (state: boolean) => void;
  selectedAudioTrack: null | TAudioTrack;
  selectedTextTrack: null | TTextTrack;
  text: {
    audioTracksText: string;
    descriptiveAudio: string;
    hardOfHearingSubtitles: string;
    off: string;
    subtitleTracksText: string;
  };
  textTracks: Array<TTextTrack>;
  textTrackVisible: boolean;
};

const LanguageList = styled.ul`
  list-style: none;
  padding: 1em;
  margin: 0;
`;

const LanguageListWrapper = styled.div<{
  borderRadius: string;
  languageOverlayColor: string;
  singleColumn: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ singleColumn }) =>
    singleColumn ? "1fr" : "1fr 1fr"};
  background: ${({ languageOverlayColor }) =>
    languageOverlayColor || "rgba(26,1,40,0.7)"};
  border-radius: ${({ borderRadius }) => borderRadius || "0.5em"};
`;

const LanguageListItemHeader = styled.li`
  font-size: 1em;
  line-height: 2;
  white-space: nowrap;
`;

const LanguageListItem = styled.li<{ checked: boolean }>`
  font-size: 0.9em;
  line-height: 2;
  padding-left: 2em;
  white-space: nowrap;

  ${({ checked }) => (checked ? "cursor: default;" : "cursor: pointer;")}

  &:not(:hover) {
    ${({ checked }) => (checked ? "" : "color: rgba(255, 255, 255, 0.66);")}
  }

  display: flex;
`;

const LanguageListItemContainer = styled.div`
  position: relative;
`;

const LanguageListItemIcon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  margin-top: -0.15em;

  width: 2em;
  line-height: 2;

  svg {
    width: 65%;
  }

  display: flex;
  align-items: center;
  justify-content: start;
`;

const LanguageItem: FC<{
  changeLanguage: TCallback;
  checked?: boolean;
  CheckIcon: TPlayerIcon;
  descriptiveAudio: string;
  hardOfHearingSubtitles: string;
  isoCode?: "off" | keyof TLanguageCode;
  text?: string;
  track?: TAudioTrack | TTextTrack;
  type: TAudioTrackType | TTextTrackType;
}> = ({
  changeLanguage,
  checked = false,
  CheckIcon,
  descriptiveAudio,
  hardOfHearingSubtitles,
  isoCode,
  text,
  track,
  type,
}) => {
  const { languages } = useTranslations();
  const showLanguageLabel = () => {
    if (text) return text;
    if (isoCode === "off") return isoCode;

    if (!isoCode) return "Mystery language";

    const ln = languages[isoCode] || isoCode;

    switch (type) {
      case "caption":
        return `${ln} (${hardOfHearingSubtitles})`;
      case "commentary":
        return `${ln} (${descriptiveAudio})`;
      default:
        return ln;
    }
  };

  const onClick = useCallback(
    () => changeLanguage(track),
    [track, changeLanguage]
  );

  return (
    <LanguageListItemContainer
      onClick={onClick}
      onDoubleClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        if (e.key === " " || e.key === "Enter") {
          onClick();
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      role="button"
      tabIndex={0}
    >
      {checked && (
        <LanguageListItemIcon tabIndex={-1}>
          <CheckIcon />
        </LanguageListItemIcon>
      )}
      <LanguageListItem checked={checked}>
        {showLanguageLabel()}
      </LanguageListItem>
    </LanguageListItemContainer>
  );
};

const LanguageMenu: FC<TLanguagePopupProps> = ({
  audioTracks,
  onBlurCallback,
  selectedAudioTrack,
  selectedTextTrack,
  text: {
    audioTracksText,
    descriptiveAudio,
    hardOfHearingSubtitles,
    off,
    subtitleTracksText,
  },
  textTracks,
  textTrackVisible,
}) => {
  const { onSelectAudioTrack, onSelectTextTrack, onSetTextTrackVisibility } =
    useClickHandler();
  const { IconCheck } = useIcon();
  const languagePopupRef = useRef(null);
  const { borderRadius, languageOverlayColor } = useCustomStyles();
  const [activeAudioTrack, setActiveAudioTrack] = useState<null | TAudioTrack>(
    selectedAudioTrack
  );
  const [activeTextTrack, setActiveTextTrack] = useState<null | TTextTrack>(
    selectedTextTrack
  );
  const [subtitlesVisible, setSubtitlesVisible] =
    useState<boolean>(textTrackVisible);

  useEffect(() => {
    setSubtitlesVisible(textTrackVisible);
    setActiveTextTrack(selectedTextTrack);
  }, [selectedTextTrack, textTrackVisible]);

  useEffect(() => {
    setActiveAudioTrack(selectedAudioTrack);
  }, [selectedAudioTrack]);

  const textTrackSelectorVisible = !!(textTracks && textTracks.length);
  const audioTrackSelectorVisible = !!(
    activeAudioTrack &&
    audioTracks &&
    audioTracks.length > 1
  );

  const singleColumn =
    (textTrackSelectorVisible && !audioTrackSelectorVisible) ||
    (!textTrackSelectorVisible && audioTrackSelectorVisible);

  const onChangeTextLanguageOff = useCallback(() => {
    setSubtitlesVisible(false);
    onSetTextTrackVisibility(false);
    setActiveTextTrack(null);
  }, [setSubtitlesVisible, onSetTextTrackVisibility, setActiveTextTrack]);

  const onChangeTextLanguage = useCallback(
    (track: TTextTrack) => {
      setSubtitlesVisible(true);
      setActiveTextTrack(track);
      onSelectTextTrack(track);
      onSetTextTrackVisibility(true);
    },
    [
      setSubtitlesVisible,
      onSetTextTrackVisibility,
      setActiveTextTrack,
      onSelectTextTrack,
    ]
  );

  const onChangeAudioLanguage = useCallback(
    (track: TAudioTrack) => {
      setActiveAudioTrack(track);
      onSelectAudioTrack(track);
    },
    [onSelectAudioTrack, setActiveAudioTrack]
  );

  return (
    <LanguagePopup
      onBlur={(e) => {
        // a little bit hacky, hide the language popup when we tab out of it
        if (
          e.relatedTarget?.parentElement?.parentElement &&
          !e.target.parentElement?.parentElement?.isEqualNode(
            e.relatedTarget?.parentElement.parentElement
          )
        ) {
          e.preventDefault();
          onBlurCallback(false);
        }
      }}
      ref={languagePopupRef.current}
    >
      <LanguageListWrapper
        borderRadius={borderRadius}
        languageOverlayColor={languageOverlayColor}
        singleColumn={singleColumn}
      >
        {textTrackSelectorVisible && (
          <LanguageList>
            <LanguageListItemHeader>
              {subtitleTracksText}
            </LanguageListItemHeader>
            <LanguageItem
              changeLanguage={onChangeTextLanguageOff}
              checked={!subtitlesVisible}
              CheckIcon={IconCheck}
              descriptiveAudio={descriptiveAudio}
              hardOfHearingSubtitles={hardOfHearingSubtitles}
              text={off}
              type="subtitle"
            />
            {textTracks.map((track) => (
              <LanguageItem
                changeLanguage={onChangeTextLanguage}
                checked={
                  subtitlesVisible &&
                  !!activeTextTrack &&
                  activeTextTrack.isoCode === track.isoCode &&
                  activeTextTrack.type === track.type
                }
                CheckIcon={IconCheck}
                descriptiveAudio={descriptiveAudio}
                hardOfHearingSubtitles={hardOfHearingSubtitles}
                isoCode={track.isoCode}
                key={`text-${track.isoCode}-${track.type}`}
                track={track}
                type={track.type}
              />
            ))}
          </LanguageList>
        )}
        {audioTrackSelectorVisible && (
          <LanguageList>
            <LanguageListItemHeader>{audioTracksText}</LanguageListItemHeader>
            {audioTracks.map((track) => (
              <LanguageItem
                changeLanguage={onChangeAudioLanguage}
                checked={
                  activeAudioTrack?.isoCode === track.isoCode &&
                  activeAudioTrack?.type === track.type
                }
                CheckIcon={IconCheck}
                descriptiveAudio={descriptiveAudio}
                hardOfHearingSubtitles={hardOfHearingSubtitles}
                isoCode={track.isoCode}
                key={`audio-${track.isoCode}-${track.type}`}
                track={track}
                type={track.type}
              />
            ))}
          </LanguageList>
        )}
      </LanguageListWrapper>
    </LanguagePopup>
  );
};

export const LanguageButton: FC<TLanguageButtonProps> = ({
  audioTracks,
  LanguageIcon,
  onMouseEnter,
  onMouseLeave,
  position,
  selectedAudioTrack,
  selectedTextTrack,
  text,
  textTracks,
  textTrackVisible,
}) => {
  const { borderRadius } = useCustomStyles();
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isActive) {
      dispatch({
        payload: {},
        type: UiEventTypes.SHOW_UI_MENU,
      });
    } else {
      dispatch({
        payload: {},
        type: UiEventTypes.HIDE_UI_MENU,
      });
    }
  }, [isActive, dispatch]);

  return (
    <LanguageSelector
      aria-label="language selector"
      isActive={isActive}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          setIsActive(false);
          e.currentTarget.focus();
          // e.currentTarget.blur();
          e.stopPropagation();
          e.preventDefault();
        }
        if (e.key === " " || e.key === "Enter") {
          onMouseEnter();
          setIsActive(!isActive);
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      position={position}
      role="button"
      tabIndex={0}
    >
      <LanguageIconWrapper borderRadius={borderRadius} tabIndex={-1}>
        <LanguageIcon />
        <LanguageMenu
          audioTracks={audioTracks}
          onBlurCallback={setIsActive}
          selectedAudioTrack={selectedAudioTrack}
          selectedTextTrack={selectedTextTrack}
          text={text}
          textTracks={textTracks}
          textTrackVisible={textTrackVisible}
        />
      </LanguageIconWrapper>
    </LanguageSelector>
  );
};
